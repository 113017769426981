<template>
  <div class="statistics">
    <div class="row">
      <div class="key">{{ $fanyi('选中订单单数') }}:</div>
      <div class="val">{{ checkedList.length }}</div>
    </div>
    <div class="row">
      <div class="key">{{ $fanyi('合计待支付金额') }}:</div>
      <div class="val">{{ statistics.amount }}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    checkedList: {type: Array},
    edit: {type: Boolean},
  },
  computed: {
    statistics() {
      let total = {
        amount: 0,
      }
      this.checkedList.forEach(item => {
        total.amount += item.pay_amount
      })
      return total
    }
  },
  data() {
    return {};
  },
  methods: {}
}
</script>
<style lang='scss' scoped>
.row {
  display: flex;
  margin-bottom: 5px;
  color: #000;

  .key {
    width: 145px;
    min-width: 145px;
    margin-right: 10px
  }

  .val {
    color: #B4272D;
  }
}

/deep/ .el-popover {
  background-color: pink !important;
}
</style>